<template>
  <v-dialog
    v-model="canShowDialog"
    max-width="674"
    @keydown.enter="applyFilters"
    @click:outside="closeDialog(false)"
    @keydown.esc="closeDialog(false)"
  >
    <v-card
      class="v-card"
      style="max-height: 70vh; display: flex; flex-direction: column;"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ dialogTitle }}
        <v-spacer/>

        <div
          class="x-image"
        >
          <v-img
            src="/img/icons/x-icon-image.svg"
            contain
            width="30"
            height="30"
            @click="closeDialog(false)"
          />
        </div>
      </v-card-title>
      <v-card-text
        class="pt-5 v-card-text"
        style="overflow-y: auto; flex-grow: 1;"
      >
        <template
          v-if="config && config.fields && config.fields.length"
        >
          <v-row
            v-for="(chunkedRow, chunkedRowIndex) in chunkFields()"
            :key="chunkedRowIndex"
          >
            <v-col
              v-for="(field, fieldIndex) in chunkedRow"
              :key="fieldIndex"
              :cols="getChunkedColsSize(chunkedRow)"
            >
              <div
                v-if="field.type === 'text' || !field.type"
                style="width: 100%; position: relative;"
              >
                <text-field
                  v-model="filtersCopy[field.key]"
                  ref="filterField"
                  :field="field"
                />
              </div>

              <div
                v-if="field.type === 'autocomplete'"
                style="width: 100%; position: relative;"
              >
                <autocomplete-field
                  v-model="filtersCopy[field.key]"
                  ref="filterField"
                  :options="field.autocomplete_options"
                  :dense="true"
                  :field="field"
                  :hide-details="true"
                  :use-label="true"
                />
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions
        class="v-card-actions"
      >
        <universal-button
          type="base"
          color="#65AD45"
          style="color: #FFFFFF; width: 100%; height: 40px !important; font-size: 14px !important; border: unset !important;"
          :label="$t('base.apply_filters')"
          @click="applyFilters"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from '../fields/TextField.vue'
import AutocompleteField from '../fields/AutocompleteField.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'

const MAX_COLUMNS = 12
const DEFAULT_ONE_COLUMN_SIZE = 6
const DEFAULT_COLUMNS_COUNT = 2
export default {
  name: 'TableFiltersDialog',

  components: {
    AutocompleteField,
    TextField,
    UniversalButton
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },

  emits: [
    'close'
  ],

  data () {
    return {
      filtersCopy: {}
    }
  },

  computed: {
    canShowDialog: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    canShowDialog: {
      immediate: true,
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            const fields = this.$refs.filterField
            if (fields && fields.length > 0) {
              fields[0].focus()
            }
          })
        }
      }
    },
    filters: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          this.filtersCopy = val
        }
      }
    }
  },

  methods: {
    applyFilters () {
      this.closeDialog(true)
    },

    closeDialog (fromApply = false) {
      this.$emit('close', fromApply)
    },

    getChunkedColsSize (chunkedRow) {
      if (this.$vuetify.breakpoint.mobile) {
        return 12
      }

      const { columnsCount } = this.config || {}

      return chunkedRow && chunkedRow.length === 1 ? (columnsCount ? MAX_COLUMNS / columnsCount : DEFAULT_ONE_COLUMN_SIZE) : MAX_COLUMNS / columnsCount
    },

    chunkFields () {
      const chunks = []
      const { fields, columnsCount } = this.config || {}

      if (!fields) {
        return []
      }

      const columnCount = columnsCount ?? DEFAULT_COLUMNS_COUNT

      for (let i = 0; i < fields.length; i += columnCount) {
        chunks.push(fields.slice(i, i + columnCount))
      }

      return chunks
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  max-width: 674px;
  border-radius: 16px;
}

.v-card-text {
  background-color: #FFFFFF;
  padding: 20px;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #111827 !important;
  border-bottom: 1px solid #E5E7EB;
}

.x-image:hover {
  cursor: pointer;
}
</style>
