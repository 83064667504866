<template>
  <v-btn
    :class="buttonClass"
    :color="colorComputed"
    :outlined="outlined"
    :disabled="disabled"
    :ripple="ripple"
    :text="text"
    :fab="fab"
    :x-small="xSmall"
    :elevation="elevation"
    :icon="icon"
    :download="download"
    :href="download && href ? href : undefined"
    @click="$emit('click', type)"
  >
    <v-icon
      v-if="prependIconComputed"
      class="prepend-icon"
      :class="prependIconClassComputed"
      :style="prependIconStyleComputed"
    >
      {{ prependIconComputed }}
    </v-icon>

    <v-img
      v-if="src"
      :src="src"
      class="mr-2"
      style="width: 16px; height: 16px; color: white;"
    />

    <span
    >
      {{ labelComputed ? labelComputed : label }}
    </span>

    <v-icon
      v-if="appendIconComputed"
      :class="appendIconClassComputed"
      :color="appendIconColor"
      :style="appendIconStyleComputed"
    >
      {{ appendIconComputed }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'UniversalButton',

  props: {
    type: {
      type: String,
      default: 'save'
    },
    label: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    prependIcon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: Boolean,
      default: true
    },
    text: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: [Object, Number],
      default: 0
    },
    src: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    fontColor: {
      type: String,
      default: ''
    },
    download: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    appendIconColor: {
      type: String,
      default: ''
    },
    appendIconStyle: {
      type: Object,
      default: () => ({})
    },
    prependIconStyle: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      buttonClass: 'primary',
      appendIconComputed: '',
      prependIconComputed: '',
      labelComputed: '',
      colorComputed: '',
      prependIconClassComputed: '',
      appendIconClassComputed: '',
      appendIconStyleComputed: {},
      prependIconStyleComputed: {}
    }
  },

  watch: {
    type: {
      immediate: true,
      handler (type) {
        this.clearComputedVariables()
        switch (type) {
          case 'edit':
            this.prependIconComputed = 'mdi-pencil-box-outline'
            this.colorComputed = '#65AD45'
            this.buttonClass = 'edit-button'
            this.prependIconClassComputed = 'edit-prepend-icon'
            if (!this.label) {
              this.labelComputed = this.$t('base.edit')
            }
            break
          case 'cancel':
            this.colorComputed = '#EF4444'
            this.buttonClass = 'cancel-button'
            if (!this.label) {
              this.labelComputed = this.$t('base.cancel')
            }
            break
          case 'cancel-dialog':
            this.buttonClass = 'cancel-dialog-button'
            if (!this.label) {
              this.labelComputed = this.$t('base.cancel')
            }
            break
          case 'save':
            this.colorComputed = '#1F2937'
            if (this.color) {
              this.colorComputed = this.color
            }
            if (this.prependIcon) {
              this.prependIconComputed = this.prependIcon
            }
            this.prependIconStyleComputed = this.prependIconStyle
            this.buttonClass = 'save-button'
            if (!this.label) {
              this.labelComputed = this.$t('base.save')
            }
            break
          case 'green-save':
            this.colorComputed = '#65AD45'
            this.buttonClass = 'save-button'
            if (!this.label) {
              this.labelComputed = this.$t('base.save')
            }
            break
          case 'new':
            this.prependIconComputed = 'mdi-plus'
            this.colorComputed = '#65AD45'
            this.buttonClass = 'new-button'
            if (!this.label) {
              this.labelComputed = this.$t('base.new')
            }
            break
          case 'delete-icon':
            this.prependIconComputed = 'mdi-delete'
            this.prependIconClassComputed = 'delete-icon'
            this.buttonClass = ''
            break
          case 'close-icon':
            this.prependIconComputed = 'mdi-close'
            this.colorComputed = '#6B7280'
            this.prependIconClassComputed = 'close-icon'
            this.buttonClass = 'no-background-hover'
            break
          case 'edit-icon':
            this.prependIconComputed = 'mdi-pencil'
            this.buttonClass = 'edit-icon-button'
            break
          case 'upload-file':
            this.colorComputed = '#65AD45'
            this.buttonClass = 'upload-file-button'
            this.prependIconClassComputed = 'edit-prepend-icon'
            if (!this.label) {
              this.labelComputed = this.$t('base.edit')
            }
            break
          case 'base':
            this.colorComputed = '#FFFFFF'
            if (this.color !== undefined) {
              this.colorComputed = this.color ? this.color : '#FFFFFF'
            }
            this.appendIconComputed = this.appendIcon
            this.appendIconStyleComputed = this.appendIconStyle
            this.prependIconStyleComputed = this.prependIconStyle
            this.prependIconComputed = this.prependIcon
            this.prependIconClassComputed = 'base-prepend-icon'
            this.appendIconClassComputed = this.$vuetify.breakpoint.mobile ? 'base-append-icon--mobile' : 'base-append-icon'
            this.buttonClass = this.$vuetify.breakpoint.mobile ? 'base-button--mobile' : 'base-button'
            break
          case 'back':
            this.colorComputed = this.color ?? '#F3F4F6'
            this.appendIconStyleComputed = this.appendIconStyle
            this.appendIconComputed = this.appendIcon ? this.appendIcon : 'mdi-chevron-left'
            this.appendIconClassComputed = this.$vuetify.breakpoint.mobile ? 'back-append-icon--mobile' : 'back-append-icon'
            this.buttonClass = this.$vuetify.breakpoint.mobile ? 'back-button--mobile' : 'back-button'
            break
        }
      }
    },
    appendIcon: {
      handler (val) {
        this.appendIconComputed = val
      }
    },
    prependIcon: {
      handler (val) {
        this.prependIconComputed = val
      }
    },
    color: {
      handler (val) {
        this.colorComputed = val !== '' ? val : '#FFFFFF'
      }
    }
  },

  methods: {
    clearComputedVariables () {
      this.prependIconComputed = ''
      this.appendIconComputed = ''
      this.buttonClass = 'primary'
      this.colorComputed = ''
      this.labelComputed = ''
      this.prependIconClassComputed = ''
    }
  }
}
</script>

<style scoped>
.edit-button {
  font-family: 'Satoshi', sans-serif;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none;
  height: 35px !important;
}

.upload-file-button {
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none;
}

.cancel-button {
  font-family: 'Satoshi', sans-serif;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none;
  height: 35px !important;
}

.cancel-dialog-button {
  color: #111827;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  text-transform: none;
}

.save-button {
  font-family: 'Satoshi', sans-serif;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none;
  height: 35px !important;
}

.new-button {
  font-family: 'Satoshi', sans-serif;
  color: white;
  font-size: 12px;
  height: 35px !important;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none;
}

.base-button {
  font-family: 'Satoshi', sans-serif;
  color: #111827;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB !important;
  text-transform: none;
  height: 35px !important;
}

.base-button--mobile {
  font-family: 'Satoshi', sans-serif;
  color: #111827;
  font-size: 10px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB !important;
  text-transform: none;
  height: 35px !important;
}

.back-button {
  font-family: 'Satoshi', sans-serif;
  color: #111827;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB !important;
  text-transform: none;
  height: 40px !important;
  width: 40px !important;
  min-width: unset !important;
}

.back-button--mobile {
  font-family: 'Satoshi', sans-serif;
  color: #111827;
  font-size: 10px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB !important;
  text-transform: none;
  height: 35px !important;
}

.delete-icon {
  color: #EF4444 !important;
}

.edit-icon-button {
  background-color: #E5E7EB;
}

.refresh-icon-button {
  color: #111827;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E7EB !important;
}

.close-icon {
  color: white !important;
  background: #6B7280;
}

.edit-prepend-icon {
  background-color: #FFFFFF;
  color: #65AD45 !important;
  width: 17px;
  height: 17px;
  margin-right: 8px;
  border-radius: 8px;
}

.base-append-icon {
  font-size: 18px !important;
  color: #6B7280;
}

.base-append-icon--mobile {
  font-size: 16px !important;
}

.back-append-icon {
  font-size: 24px !important;
  color: #6B7280 !important;
}

.back-append-icon--mobile {
  font-size: 16px !important;
  color: #6B7280 !important;
}

.base-prepend-icon {
  color: #6B7280 !important;
  margin-right: 6px;
}

.prepend-icon {
  font-family: 'Satoshi', sans-serif;
  font-size: 24px;
  margin-right: 4px;
  font-weight: 500;
}
</style>
