<template>
  <div
    style="height: inherit; align-content: center; overflow: auto;"
  >
    <template
      v-if="cellType === 'image-icon'"
    >
      <v-img :src="cellData" :style="getImageIconStyle(header)" />
    </template>

    <template
      v-else-if="cellType === 'image'"
    >
      <v-img
        :src="cellData"
        contain
        :style="getImageIconStyle(header)"
      />
    </template>

    <template
      v-else-if="cellType === 'icon'"
    >
      <v-icon
        :style="getIconStyle(header)"
      >
        {{ cellData }}
      </v-icon>
    </template>

    <template
      v-else-if="cellType === 'status'"
    >
      <div
        :style="getStatusStyle(header)"
      >
        {{ getStatusCellData(header) }}
      </div>
    </template>

    <template v-else>
      {{ cellData }}
    </template>
  </div>
</template>

<script>
const DEFAULT_IMAGE_ICON_STYLE = {
  width: '180%',
  height: '100%'
}

const DEFAULT_ICON_STYLE = {
  width: '18px',
  height: '18px'
}

const DEFAULT_STATUS_STYLE = {}
export default {
  name: 'CellContent',

  props: {
    cellType: {
      type: null,
      required: true,
      default: 'base'
    },
    cellData: {
      type: [Object, String, Number, Boolean],
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    cellsConfig: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  methods: {
    getImageIconStyle (header) {
      const { key } = header || {}
      const customStyle = DEFAULT_IMAGE_ICON_STYLE

      if (!key) {
        return customStyle
      }

      const { imageStyle } = this.cellsConfig[key] || {}

      return {
        ...customStyle,
        ...imageStyle
      }
    },

    getIconStyle (header) {
      const { key } = header || {}
      const customStyle = DEFAULT_ICON_STYLE

      if (!key) {
        return customStyle
      }

      const { iconStyle } = this.cellsConfig[key] || {}

      return {
        ...customStyle,
        ...iconStyle
      }
    },

    getStatusStyle (header) {
      const { key } = header || {}
      const customStyle = DEFAULT_STATUS_STYLE

      if (!key) {
        return customStyle
      }

      const { statusStyle, textColorKey, backgroundColorKey, colorObjectKey } = this.cellsConfig[key] || {}
      let textColorFetched, backgroundColorFetched

      if (this.cellData && this.cellData[colorObjectKey]) {
        textColorFetched = this.cellData[colorObjectKey][textColorKey]
        backgroundColorFetched = this.cellData[colorObjectKey][backgroundColorKey]
      }

      return {
        ...customStyle,
        ...statusStyle,
        ...{
          color: textColorFetched ?? '#000000',
          border: '1px solid',
          borderColor: textColorFetched ?? '#000000',
          backgroundColor: backgroundColorFetched ?? '#FFFFFF'
        }
      }
    },

    getStatusCellData (header) {
      const { displayValue } = this.cellsConfig[header.key] || {}
      if (displayValue) {
        return this.cellData[displayValue]
      }
      else {
        return '-'
      }
    }
  }
}
</script>
