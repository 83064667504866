<template>
  <div
  >
    <v-tabs
      v-model="tab"
      grow
      hide-slider
    >
      <v-tab
        v-if="field && (!field.subType || field.subType === 'date')"
      >
        <v-icon>mdi-calendar</v-icon>
      </v-tab>
      <v-tab
        v-if="field && (!field.subType || field.subType === 'time')"
      >
        <v-icon>mdi-clock</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="field && (!field.subType || field.subType === 'date')"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="datePickerLabel"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          first-day-of-week="1"
          :min="field && field.minDate ? field.minDate : undefined"
          :max="field && field.maxDate ? field.maxDate : undefined"
          @dblclick:date="onDateDblClick"
        />
      </v-tab-item>
      <v-tab-item
        v-if="field && (!field.subType || field.subType === 'time')"
      >
        <v-time-picker
          v-model="time"
          :format="field && field.timeFormat ? field.timeFormat : '24hr'"
          :min="field && field.minTime ? field.minTime : undefined"
          :max="field && field.maxTime ? field.maxTime : undefined"
          scrollable
        />
      </v-tab-item>
    </v-tabs-items>
    <v-row no-gutters>
      <v-col class="white text-right pb-2 px-2">
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="applyDate"
        >
          {{ $t('base.ok') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatSqlDateTime, formatIsoDateCustom, defaultDateTimeFormat, validateDateTimeFormat, formatSqlDate } from '@/global/services/helpers/dates'

export default {
  name: 'DatetimePicker',

  props: {
    value: {
      type: String,
      default: null
    },
    field: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      tab: null,
      date: null,
      time: null,
      datePickerLabel: null,
      lastDateTime: null
    }
  },

  computed: {
    getDateTimeFormat () {
      return this.field && this.field.format ? this.field.format : defaultDateTimeFormat
    },

    getTimeFormat () {
      return this.getDateTimeFormat ? this.getDateTimeFormat.split(' ')[1] : undefined
    },

    getDateFormat () {
      return this.getDateTimeFormat ? this.getDateTimeFormat.split(' ')[0] : undefined
    }
  },

  emits: [
    'input',
    'close',
    'reset-date',
    'applied-date',
    'dblclick:date'
  ],

  watch: {
    date (date) {
      this.datePickerLabel = formatSqlDateTime(date)
    },

    value: {
      immediate: true,
      handler (value) {
        if (value) {
          const { format = defaultDateTimeFormat } = this.field || {}
          if (validateDateTimeFormat(value, format)) {
            this.updatePickers(value)
          }
          else {
            this.$emit('reset-date')
            this.updatePickers()
          }
        }
      }
    }
  },

  mounted () {
    document.addEventListener('keydown', this.enterAndEscapeEventHandler)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.enterAndEscapeEventHandler)
  },

  methods: {
    onDateDblClick () {
      this.$emit('dblclick:date', this)
    },

    enterAndEscapeEventHandler (event) {
      const { closeWithEscapeEnter } = this.field || {}

      // Close on enter or escape only if closeWithEscapeEnter is provided from field config
      if (!closeWithEscapeEnter) {
        return
      }

      if (event.key === 'enter') {
        this.updateFinalDatetimeValue()
        this.$emit('close')
      }

      else if (event.key === 'Escape') {
        this.$emit('close')
      }
    },

    updateFinalDatetimeValue () {
      const finalDate = this.date ? this.date : null
      const finalTime = this.time ? this.time : '00:00'

      if (this.field.subType) {
        if (this.field.subType === 'time') {
          this.lastDateTime = finalTime
          this.$emit('input', this.lastDateTime)
        }
        else if (this.field.subType === 'date') {
          this.lastDateTime = formatSqlDate(finalDate, this.getDateTimeFormat)
          this.$emit('input', this.lastDateTime)
        }
      }
      else if (finalDate) {
        this.lastDateTime = formatSqlDateTime(finalDate + ' ' + finalTime, this.getDateTimeFormat)
        // Emit final date-time in provided date-time format.
        // if date-time format is not provided, default date-time format will be applied 'DD.MM.YYYY HH:mm:ss'
        this.$emit('input', this.lastDateTime)
      }
    },

    updatePickers (value) {
      if (value && Date.parse(value) !== Date.parse(this.lastDateTime)) {
        if (this.field && this.field.subType) {
          if (this.field.subType === 'time') {
            this.time = value
          }
          else if (this.field.subType === 'date') {
            this.date = formatIsoDateCustom(value, this.getDateFormat)
          }
        }
        else {
          this.date = formatIsoDateCustom(value, this.getDateFormat)
          this.time = value.split(' ')[1]
        }
      }
    },

    applyDate () {
      this.updateFinalDatetimeValue()
      this.$emit('close')
      this.$emit('applied-date')
    }
  }
}
</script>

<style scoped>
::v-deep.v-input {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #111827 !important;
  font-weight: 500 !important;
}
::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #9CA3AF !important;
  font-weight: 400 !important;
}

::v-deep.v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon--prepend-inner > .v-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}

::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #9CA3AF !important;
    font-weight: 400 !important;
  }

  .v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }
  .v-input > .v-input__control > .v-input__slot > .v-text-field__slot > input, label {
    font-size: 0.8125rem;
    color: #111827;
  }
  .custom-text-field input[type='number'] {
    -moz-appearance:textfield;
  }
  .custom-text-field input::-webkit-outer-spin-button,
  .custom-text-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>
