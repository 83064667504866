<template>
  <v-dialog
    v-model="showDialog"
    width="500px"
    class="v-dialog"
    persistent
    @keydown.esc="closeDialog"
    @keydown.enter="confirmDialog"
  >
    <v-card
      class="v-card"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ title ? title : '' }}
        <v-spacer/>

        <div
          class="x-image"
        >
          <v-img
            src="/img/icons/x-icon-image.svg"
            contain
            width="30"
            height="30"
            @click="closeDialog"
          />
        </div>
      </v-card-title>
      <v-card-text
        class="full-height pa-5 d-flex flex-column dialog-message"
      >
          {{ dialogMessage }}
      </v-card-text>
      <v-card-actions>
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <universal-button
              type="cancel-dialog"
              :label="negativeButtonText"
              outlined
              class="universal-buttons"
              @click="closeDialog"
            />
          </v-col>
          <v-col>
            <universal-button
              type="cancel"
              :label="positiveButtonText"
              class="universal-buttons"
              @click="confirmDialog"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
export default {
  name: 'InformationDialog',

  components: {
    UniversalButton
  },

  props: {
    dialogMessage: {
      type: String,
      default: ''
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    positiveButtonText: {
      type: String,
      default: ''
    },
    negativeButtonText: {
      type: String,
      default: ''
    },
    confirmCallbackFunction: {
      type: Function,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.openDialog
      },
      set (value) {
        return value
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    async confirmDialog () {
      if (this.confirmCallbackFunction && typeof this.confirmCallbackFunction === 'function') {
        await this.confirmCallbackFunction(this)
      }
      this.$emit('close-inner')
    }
  }
}
</script>

<style scoped>
.dialog-message {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px;
  color: #111827 !important;
}

.v-card {
  border-radius: 16px;
  text-align: center;
}

.v-dialog {
  border-radius: 20px;
  padding: 40px 20px 20px 20px;
}

.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #111827 !important;
  border-bottom: 1px solid #E5E7EB;
}

.x-image:hover {
  cursor: pointer;
}
</style>
