<template>
  <div>
    <div>
      <span
        id="autocomplete-field-label"
      >
         {{ dynamic ? ($vuetify.breakpoint.mobile && field && field.label ? field.label : '' ) : (field && field.label ? field.label : '') }}
      </span>
      <v-tooltip
        v-if="field && field.tooltip"
        top
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-icon
            class="ml-2"
            :style="field.tooltip.style ? field.tooltip.style :  getDefaultIconTooltipStyle()"
            v-bind="attrs"
            v-on="on"
          >
            {{ getTooltipIcon() }}
          </v-icon>
        </template>
        <span>
          {{ getTooltipText() }}
        </span>
      </v-tooltip>
    </div>
    <div
      :style="{ cursor: field && !field.editable ? 'not-allowed' : 'default' }"
    >
      <v-autocomplete
        ref="input"
        v-model="selectedItem"
        class="mt-1"
        :items="processedItems"
        :item-value="getItemValue()"
        item-text="displayText"
        :placeholder="getPlaceholder()"
        :readonly="readonly"
        :disabled="disabled"
        :append-icon="appendIcon"
        :loading="fetchingData"
        outlined
        hide-details="auto"
        :error-messages="getValidationErrors()"
        :error-count="getValidationErrors().length"
        :return-object="shouldReturnObject()"
        dense
        :style="{ backgroundColor: field && field.editable !== undefined && !field.editable ? '#E5E7EB' : '' }"
        :search-input.sync="searchInput"
        @blur="handleBlur"
        @change="emitClearValidationError"
        @input.native="onInput"
      >
        <template #append-item>
          <v-list-item
            v-if="hasMoreItems"
            class="has-more-items"
            @click="makeSearch(searchInput ? searchInput : '', true)"
          >
            <v-list-item-title class="font-italic text-decoration-underline">
              {{ $t('base.got_more_items') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { isEmpty, isObject } from 'lodash'
import { api } from '@/global/services/api'
import { isNumber } from 'lodash/lang'

const DEFAULT_ITEM_VALUE = 'id'
const DEFAULT_ITEM_TEXT = 'name'
const DEFAULT_PLACEHOLDER_VALUE = ''
const DEFAULT_TOOLTIP_COLOR = '#6B7280'
const DEFAULT_TOOLTIP_ICON = 'mdi-information-slab-circle'
const DEFAULT_TOOLTIP_TEXT = ''
const DEFAULT_TOOLTIP_FONT_SIZE = '16px'
const DEFAULT_TOOLTIP_MARGIN_BOTTOM = '1px'
const DEFAULT_SEARCH_TIMEOUT = 500
export default {
  name: 'AutocompleteField',

  props: {
    appendIcon: {
      type: String,
      default: '$dropdown'
    },
    value: {
      type: [Number, String, Object, Boolean],
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => ({})
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    collectionKey: {
      type: String,
      default: ''
    },
    collectionIndex: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Function,
      default: () => {}
    }
  },

  emits: [
    'input',
    'clear-validation-errors'
  ],

  data () {
    return {
      fetchingData: false,
      hasMoreItems: false,
      searchInput: '',
      internalItems: [],
      searchTimeoutId: null,
      calledFromPickId: false,
      pickedId: null
    }
  },

  computed: {
    selectedItem: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },

    processedItems () {
      const mappedItems = this.internalItems.map(item => ({
        ...item,
        displayText: this.getItemText(item)
      }))

      return mappedItems.filter(item => item.displayText)
    }
  },

  watch: {
    items: {
      immediate: true,
      deep: true,
      async handler (items) {
        if (items) {
          const { key } = this.field || {}
          if (key && items[key]) {
            this.internalItems = items[key].data.map(item => ({
              ...item,
              id: parseInt(item.id, 10)
            }))
            this.hasMoreItems = !!items[key]?.hasMore
          }
        }
      }
    },
    searchInput: {
      handler (val) {
        const { allowSearch } = this.options || {}
        if (val !== null && val !== undefined && typeof val === 'string' && (allowSearch === undefined || allowSearch)) {
          if (!val) {
            this.searchInput = ''
            this.selectedItem = null
          }

          clearTimeout(this.searchTimeoutId)
          this.searchTimeoutId = setTimeout(async () => {
            const { form_value: formValue } = this.options || {}
            const alreadyExistObj = this.internalItems.find(obj => formValue && obj[formValue] ? obj[formValue] === val : (obj[DEFAULT_ITEM_TEXT] ? obj[DEFAULT_ITEM_TEXT] === val : false))
            if (!alreadyExistObj) {
              await this.makeSearch(val)
            }
          }, DEFAULT_SEARCH_TIMEOUT)
        }
      }
    },
    pickedId: {
      async handler (val) {
        if (val && isNumber(val)) {
          await this.makeSearch()
        }
      }
    }
  },

  async created () {
    const { fetchInitial } = this.options || {}
    if (fetchInitial || fetchInitial === undefined) {
      await this.makeSearch()
    }
  },

  methods: {
    isEmpty,

    async handleBlur () {
      const { keepInput, onSelect } = this.field || {}
      if (!this.internalItems.some(item => this.getItemText(item) === this.searchInput) && keepInput && typeof keepInput === 'function') {
        // If the user's input doesn't match any item, store the text directly
        const customSelectedValue = keepInput(this.searchInput)
        const { value, items } = customSelectedValue

        this.selectedItem = value
        this.internalItems = items
      }

      if (onSelect && typeof onSelect === 'function') {
        const callbackData = {
          selectedItem: this.selectedItem,
          instance: this.$parent?.$parent?.$parent || {},
          updateData: (data) => this.callUpdateData(data),
          searchInput: this.searchInput
        }

        onSelect(callbackData)
      }
    },

    callUpdateData (data) {
      if (this.updateData && typeof this.updateData === 'function') {
        this.updateData(data)
      }
    },

    async makeSearch (query = null, allItems = false) {
      try {
        this.fetchingData = true
        const pickedId = this.pickedId ? this.pickedId : (this.value && this.value.id ? this.value.id : null)
        const { additionalQueryParams } = this.options || {}

        let params = {
          query: query && typeof query === 'string' ? query : '',
          pickedId: query ? null : pickedId,
          includeAll: allItems
        }

        if (additionalQueryParams && typeof additionalQueryParams === 'function') {
          const paramsFromCallback = additionalQueryParams(this)
          params = {
            ...params,
            ...paramsFromCallback
          }
        }
        // Add additional query params
        else if (additionalQueryParams && isObject(additionalQueryParams)) {
          params = {
            ...params,
            ...additionalQueryParams
          }
        }

        if (this.options && this.options.module && this.options.route) {
          const { data, has_more: hasMore = false } = await api()[this.options.module].get(this.options.route, params)

          if (this.pickedId) {
            this.pickedId = null
          }

          if (data && data.length) {
            this.internalItems = data.map(item => ({
              ...item,
              id: parseInt(item.id, 10)
            }))

            this.hasMoreItems = hasMore
          }
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to fetch autocomplete data. Exception: ', exception)
      }
      finally {
        this.fetchingData = false
      }
    },

    emitClearValidationError (selectedValue) {
      const { onSelect } = this.field || {}

      if (onSelect && typeof onSelect === 'function') {
        const callbackData = {
          selectedItem: selectedValue,
          instance: this.$parent?.$parent?.$parent || {},
          updateData: (data) => this.callUpdateData(data),
          searchInput: this.searchInput
        }

        onSelect(callbackData)
      }

      this.$emit('clear-validation-errors')
    },

    onInput () {
      this.$emit('clear-validation-errors')
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    },

    getItemText (item) {
      let returnValue = item && item.name ? item.name : DEFAULT_ITEM_TEXT

      if (this.options && this.options.custom_display_value) {
        returnValue = this.options.custom_display_value(item)
      }

      return returnValue && typeof returnValue === 'string' ? returnValue : null
    },

    getItemValue () {
      const { form_value: formValue } = this.options || {}

      return formValue && typeof formValue === 'string' && !formValue.includes('.') ? formValue : DEFAULT_ITEM_VALUE
    },

    shouldReturnObject () {
      return this.field && this.field.autocomplete_options && this.field.autocomplete_options.returnValue && this.field.autocomplete_options.returnValue === 'object'
    },

    getPlaceholder () {
      return this.field && this.field.placeholder && typeof this.field.placeholder === 'string' ? this.field.placeholder : DEFAULT_PLACEHOLDER_VALUE
    },

    getTooltipText () {
      return this.field && this.field.tooltip && this.field.tooltip.text ? this.field.tooltip.text : DEFAULT_TOOLTIP_TEXT
    },

    getTooltipIcon () {
      return this.field && this.field.tooltip && this.field.tooltip.icon ? this.field.tooltip.icon : DEFAULT_TOOLTIP_ICON
    },

    getDefaultIconTooltipStyle () {
      return {
        color: DEFAULT_TOOLTIP_COLOR,
        fontSize: DEFAULT_TOOLTIP_FONT_SIZE,
        marginBottom: DEFAULT_TOOLTIP_MARGIN_BOTTOM
      }
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else {
          const formattedCollectionKey = this.collectionKey + '.' + this.collectionIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-select__slot > input {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #9CA3AF !important;
  font-weight: 400 !important;
}

::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-select__slot > input {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #9CA3AF !important;
    font-weight: 400 !important;
  }

  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }

  .v-input > .v-input__control > .v-input__slot > .v-select__slot > input {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #111827;
    font-weight: 500 !important;
  }
}

#autocomplete-field-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
